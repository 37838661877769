import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import axios from 'axios'

const withTokenValidation = (Component) => {
  console.log('componrent calling =====')
  const WithTokenValidation = (props) => {
    const [isValidToken, setIsValidToken] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
      const validateToken = async () => {
        const token = localStorage.getItem('token')
        if (token) {
          try {
            // Make API call to validate token
            const response = await axios.get(`https://api.u4u.app/auth`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            setIsValidToken(response.data && response.data.status)
          } catch (error) {
            console.error('Error validating token:', error)
            setIsValidToken(false)
          }
        } else {
          setIsValidToken(false)
        }
        setIsLoading(false)
      }

      validateToken()
    }, [])

    if (isLoading) {
      return <div>Loading...</div> // You can replace this with a loading spinner
    }
    console.log('isValidToken:=========', isValidToken)
    if (isValidToken) {
      return <Component {...props} />
    } else {
      return <Navigate to="/login" />
    }
  }

  return WithTokenValidation
}

export default withTokenValidation
